import React, { useState } from "react";
import { navigate } from "gatsby";
import { Form, Input, Button, message } from "antd";
import { Auth } from "aws-amplify";
import { Layout, SEO } from "../components";

const AuthPage = () => {
  const [error, setError] = useState();

  const onFinish = (values) => {
    confirmSignUp(values.email, values.authCode);
  };

  const onFinishFailed = (errorInfo) => {
    setError(errorInfo);
  };

  const confirmSignUp = async (email, authCode) => {
    try {
      await Auth.confirmSignUp(email, authCode);
      alert("Successfully signed up, please login!");
      navigate("/");
    } catch (err) {
      setError(err);
    }
  };

  return (
    <Layout>
      <SEO title="Authenticate" />

      <div className="AuthPage">
        {error && message.error("Something went wrong please try again")}

        <h2>Sign up</h2>
        <Form
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Authentication Code"
            name="authCode"
            rules={[
              {
                required: true,
                message: "Please check your email for an authentication code",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ margin: "auto" }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default AuthPage;
